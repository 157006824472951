import moment from 'moment-timezone'

export function dateDifference(date1, date2) {

    var month1 = date1.getUTCMonth(); //months from 1-12
    var day1 = date1.getUTCDate();
    var year1 = date1.getUTCFullYear();

    var month2 = date2.getUTCMonth(); //months from 1-12
    var day2 = date2.getUTCDate();
    var year2 = date2.getUTCFullYear();

    var a = moment([year1, month1, day1]);
    var b = moment([year2, month2, day2]);

    const years = a.diff(b, 'years');
    const months = a.diff(b, 'months')
    const days = a.diff(b, 'days')

    return {
        years,
        months,
        days
    }
}